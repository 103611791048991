import { useStaticQuery, graphql } from "gatsby"

export const useCategoryGroups = () => {
  const { allContentfulCategoryGroup } = useStaticQuery(graphql`
        query CategoryGroupQuery {
            allContentfulCategoryGroup {
                edges 
                {
                    node 
                    {
                        id
                        name
                        slug
                    }
                }
            }
      }
    `
  )

  return allContentfulCategoryGroup //.edges.map(entry => entry.node);
}