import React from 'react'
import { Link } from 'gatsby'
import { useCategoryGroups } from "../hooks/get-category-groups"

export default () => {
  const categoryGroups = useCategoryGroups()

  return <footer class="plus_border">
		<div class="container margin_60_35">
			<div class="row">
				<div class="col-lg-3 col-md-6 col-sm-6">
					<h3 data-target="#collapse_ft_1">Links</h3>
					<div class="collapse dont-collapse-sm" id="collapse_ft_1">
						<ul class="links">
              <li><Link to="/info/sobre-nosotros/">Sobre Nosotros</Link></li>
              <li><Link to="/info/oportunidades-de-trabajo/">Oportunidades de Trabajo</Link></li>
              <li><Link to="/info/contacto/">Contacto</Link></li> 
              <li><Link to="/info/consumo-local/">Consumo Local</Link></li>
              <li><Link to="/info/prevencion-de-fraudes/">Fraudes</Link></li>             
						</ul>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<h3 data-target="#collapse_ft_2">Categorias</h3>
					<div class="collapse dont-collapse-sm" id="collapse_ft_2">
						<ul class="links">              
              {categoryGroups && categoryGroups.edges.map(edge => {
                return <li key={edge.node.id}><Link to={ "/cg/" + edge.node.slug }>{edge.node.name}</Link></li>
              })}
						</ul>
					</div>
				</div>
				<div class="col-lg-3 col-md-6 col-sm-6">
					<h3 data-target="#collapse_ft_3">Contacto</h3>
					<div class="collapse dont-collapse-sm" id="collapse_ft_3">
						<ul class="contacts">
							<li><i class="ti-home"></i>Georgia 3100<br />Chihuahua, México</li>							
							<li><i class="ti-email"></i><a href="mailto:info@confiable.club">info@confiable.club</a></li>
						</ul>
					</div>
				</div>
        {/* 
				<div class="col-lg-3 col-md-6 col-sm-6">
					<h3 data-target="#collapse_ft_4">Keep in touch</h3>
					<div class="collapse dont-collapse-sm" id="collapse_ft_4">
						<div id="newsletter">
							<div id="message-newsletter"></div>
							<form method="post" action="assets/newsletter.php" name="newsletter_form" id="newsletter_form">
								<div class="form-group">
									<input type="email" name="email_newsletter" id="email_newsletter" class="form-control" placeholder="Your email">
									<input type="submit" value="Submit" id="submit-newsletter">
								</div>
							</form>
						</div>
						<div class="follow_us">
							<h5>Follow Us</h5>
							<ul>
								<li><a href="#0"><i class="ti-facebook"></i></a></li>
								<li><a href="#0"><i class="ti-twitter-alt"></i></a></li>
								<li><a href="#0"><i class="ti-google"></i></a></li>
								<li><a href="#0"><i class="ti-pinterest"></i></a></li>
								<li><a href="#0"><i class="ti-instagram"></i></a></li>
							</ul>
						</div>
					</div>
				</div>*/}
			</div>
		
			<hr />
			<div class="row">
				<div class="col-lg-6">
					
				</div>
				<div class="col-lg-6">
					<ul id="additional_links">
						<li><Link to="/info/privacidad/">Políticas de Privacidad</Link></li>
            <li><Link to="/info/terminos-y-condiciones/">Términos y Condiciones</Link></li>
						<li><span>© 2020 Confiable.club</span></li>
					</ul>
				</div>
			</div>
		</div>
  </footer>
}

