import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import LogoImage from './confiable.svg';
import { useCategoryGroups } from "../hooks/get-category-groups"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"

export default () => {	 
	const categoryGroups = useCategoryGroups()
	var user = null;
	const isUserAuthenticated = isAuthenticated();

	if(isUserAuthenticated){
		user = getProfile();
	}

	return (
		
		<Container>		
		<Navbar collapseOnSelect expand="lg" sticky="top" bg="white" >
		  <Navbar.Brand>
			<Link to="/">
			<img
			  alt=""
			  src={LogoImage}			  
			  height="60"
			  className="d-inline-block align-top"
			/>{' '}</Link>			
		  </Navbar.Brand>
		  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
		  <Navbar.Collapse id="responsive-navbar-nav">
		  	 <Nav>
				  <Nav.Link as={Link} to="/">Inicio</Nav.Link>
				  <Nav.Link as={Link} to="/signup">Registrar Empresa</Nav.Link>
				  <NavDropdown title="Categorias" id="collapsible-nav-dropdown">
				   		{categoryGroups && categoryGroups.edges.map(edge => {
						return <NavDropdown.Item key={edge.node.id} as={Link} to={ "/cg/" + edge.node.slug }>{edge.node.name}</NavDropdown.Item>
							})}							
					</NavDropdown>
			  </Nav>
			  <Nav>				
				{!isUserAuthenticated &&								
								<Nav.Link as={Link} to="/account/" className="login" alt="Iniciar Sesion">Iniciar Sesion</Nav.Link>
							}
				{isUserAuthenticated && 	
								<NavDropdown title="Mi Cuenta" id="collapsible-nav-dropdown">
									<NavDropdown.Item as="span">
										<div style={{ color : "Black "}}>	
													<img src={user.picture} style={{ maxWith : "50px", maxHeight : "50px" }}/>
													<span>
														<div style={{ padding : "5px"}}>{user.name}</div>
														<div style={{ padding : "5px"}}>{user.email}</div>
													</span>
												</div>
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to="/account">
										Ir a Cuenta
									</NavDropdown.Item>
									<NavDropdown.Item as="span">
										<a href="#" onClick={e => {
													logout()    
													e.preventDefault()
												}}>Cerrar Sesion</a>
									</NavDropdown.Item>
								</NavDropdown>																	
								}
			  </Nav>

			  
		  </Navbar.Collapse>
		</Navbar>
	  </Container>

			)
}
