import React from 'react'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Footer from '../components/footer'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Container>
        <Helmet>
          
        </Helmet>
        <Navigation />
        {children}
        
        <script src={withPrefix('common_scripts.js')} type="text/javascript" />
        <script src={withPrefix('functions.js')} type="text/javascript" />  

        <Footer />      
      </Container>
    )
  }
}

export default Template
